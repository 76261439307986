import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  BaseButton,
  FormHelper,
  ImageLogic,
  NumberHelper,
  SearchLogic,
  useBreakPointDown,
} from "blace-frontend-library";
import cn from "classnames";
import { useBlocker } from "react-router-dom";
import { BaseIcon } from "@/src/component/base";
import { TextareaDescription } from "@/src/component/base/TextareaDescription";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { SaveButton } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/DetailsContent/components/SaveButton";
import RoomContentContext from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/RoomContentContext";
import { RoomPhotosPopup } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/component/RoomPhotosPopup";
import { SUPPORTED_IMAGE_FORMATS, UNSAVED_CHANGES_WARNING_TEXT } from "@/src/const";
import { useRoomForm } from "@/src/hook/useRoomForm";
import { FormLogic } from "@/src/model";
import {
  BlockerArgs,
  FormRef,
  PriceDurationBE,
  PriceDurationFE,
  RoomPhotoFile,
} from "@/src/type/app";
import { SearchImage, SearchRoomV2 } from "@/src/type/blaceV2/search/SearchType";
import styles from "./RoomForm.module.scss";

interface RoomsFormProps {
  onSaveRoomFormData?: () => void;
}

export enum InputList {
  id = "id",
  RoomName = "name",
  RoomDescription = "description",
  FloorsNumber = "numberOfFloors",
  SquareFootage = "sqFootage",
  Capacity = "maxCapacity",
  Images = "images",
  ShowPricing = "showPricing",
  Price = "pricingValueInCents",
  PriceDuration = "pricingDuration",
}

function RoomForm(props: RoomsFormProps, ref: React.Ref<FormRef>) {
  const { onSaveRoomFormData } = props;
  const {
    selectedRoom,
    setSelectedRoom,
    setRooms,
    allRooms,
    handlePublishRoomClick,
    deactivateRoom,
  } = RoomContentContext.useRoomContext();
  const [isPhotosPopupOpen, setIsPhotosPopupOpen] = useState(false);
  const [roomPhotos, setRoomPhotos] = useState<RoomPhotoFile[]>([]);

  const isMobile = useBreakPointDown("md");

  const {
    setIsSaveButtonDisabled,
    isEditRequestSubmitting,
    isSaveButtonDisabled,
    hasUnsavedData,
    listingItemSaveHandler,
    setHasUnsavedData,
  } = useContext(ListingManagementContext) || {};

  const handleSaveRoom = async (roomData: Partial<SearchRoomV2>) => {
    const roomsToSave = allRooms.map((room) => {
      const editedRoom = isMobile && room.id === roomData.id ? roomData : room;
      if (editedRoom.showPricing && !editedRoom.pricingDuration) {
        editedRoom.pricingDuration = PriceDurationBE.PerDay;
      }
      if (!SearchLogic.isRoomValidForPublish(room)) {
        return { ...editedRoom, isPublished: false };
      }
      return editedRoom;
    });

    const errors = listingItemSaveHandler
      ? await listingItemSaveHandler({
          rooms: (roomsToSave as SearchRoomV2[]) ?? [],
        })
      : {};

    if (Object.keys(errors).length === 0 && isMobile) {
      setSelectedRoom(undefined);
    }
    return errors;
  };

  const { formik } = useRoomForm({
    roomSaveHandler: handleSaveRoom,
    roomData: selectedRoom,
  });

  const shouldBlockFormSave = !formik.isValid || formik.isSubmitting || !!isEditRequestSubmitting;
  const isMobileSaveDisabled = shouldBlockFormSave || !formik.dirty;
  const isDesktopSaveDisabled = shouldBlockFormSave || !hasUnsavedData;

  const isPublishDisabled =
    !selectedRoom?.isPublishEnabled ||
    !isSaveButtonDisabled ||
    !formik.isValid ||
    (isMobile && !isMobileSaveDisabled) ||
    isEditRequestSubmitting;

  const isPhotosError = FormHelper.formikCheckError(formik, InputList.Images);

  useImperativeHandle(ref, () => ({
    submitForm: formik.handleSubmit,
  }));

  const markHasUnsavedData = useCallback(() => {
    setHasUnsavedData && setHasUnsavedData(true);
  }, [setHasUnsavedData]);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }: BlockerArgs) =>
      Boolean(hasUnsavedData) && currentLocation.pathname !== nextLocation.pathname,
  );

  const updateRoomField = (field: string, value?: string | SearchImage[] | number | boolean) => {
    if (isMobile) {
      return;
    }

    setRooms(
      allRooms?.map((room) => {
        if (room.id === selectedRoom?.id) {
          const fieldValue = value === "" || value === 0 ? undefined : value;
          return { ...room, [field]: fieldValue };
        }
        return room;
      }),
    );
    markHasUnsavedData();
  };

  const handleSelectRoomPhotos = () => {
    setIsPhotosPopupOpen(false);
    const roomImageToSave: SearchImage[] = roomPhotos.map((photo, index) => ({
      imageHash: photo.imageHash,
      originLink: photo.originLink,
      order: index,
      contentType: photo.contentType,
    }));
    formik.setFieldValue(InputList.Images, roomImageToSave);
    updateRoomField(InputList.Images, roomImageToSave);
  };

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (window && window.confirm(UNSAVED_CHANGES_WARNING_TEXT)) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

  // prevent data loss
  useEffect(() => {
    if (hasUnsavedData) {
      window.addEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    } else {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    }
  }, [hasUnsavedData]);

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
      setHasUnsavedData && setHasUnsavedData(false);
    };
    // Run only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update the save button status
  useEffect(() => {
    if (!isMobile && setIsSaveButtonDisabled) {
      setIsSaveButtonDisabled(isDesktopSaveDisabled);
    }
  }, [isDesktopSaveDisabled, isMobile, setIsSaveButtonDisabled, hasUnsavedData]);

  // reset the photos, when we go to another room as we have 1 form and changing room data so next useEffect won't run every time
  useEffect(() => {
    setRoomPhotos(selectedRoom?.images || []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoom?.id]);

  return (
    <div className={styles.roomsForm}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.formItem}>
          <label htmlFor={InputList.RoomName} className={cn(styles.inputLabel, styles.required)}>
            Room Name
          </label>
          <TextField
            className={styles.textField}
            placeholder="Add Room Title"
            fullWidth
            required
            disabled={isEditRequestSubmitting}
            value={formik.values[InputList.RoomName]}
            onChange={(e) => {
              formik.setFieldValue(InputList.RoomName, e?.target?.value ?? "");
              updateRoomField(InputList.RoomName, e?.target?.value);
            }}
            helperText={FormHelper.formikErrorMessage(formik, InputList.RoomName)}
            error={FormHelper.formikCheckError(formik, InputList.RoomName)}
            id={InputList.RoomName}
          />
        </div>
        <div className={styles.formItem}>
          <label className={cn(styles.inputLabel, styles.required)}>Room photos</label>

          <label
            htmlFor={InputList.Images}
            className={styles.hiddenInputWrapper}
            style={{
              background: `url(${ImageLogic.getImageUrl(
                formik.values[InputList.Images][0]?.imageHash,
                80,
                300,
              )})`,
            }}
            onClick={(e) => {
              e.preventDefault();
              if (!formik.isSubmitting) {
                setIsPhotosPopupOpen(true);
              }
            }}
          >
            <Typography className={styles.hiddenInputPlaceholderText}>Select Photos</Typography>
            <input
              className={styles.hiddenInput}
              id={InputList.Images}
              multiple
              type="file"
              accept={SUPPORTED_IMAGE_FORMATS.join(",")}
            />
          </label>
          {isPhotosError && (
            <FormHelperText error>
              {FormHelper.formikErrorMessage(formik, InputList.Images)}
            </FormHelperText>
          )}
        </div>
        <div className={styles.formItem}>
          <label
            htmlFor={InputList.RoomDescription}
            className={cn(styles.inputLabel)}
          >
            Room Description
          </label>
          <TextField
            className={cn(styles.textField, styles.roomDescription)}
            placeholder="Add full description of your Room"
            fullWidth
            disabled={isEditRequestSubmitting}
            value={formik.values[InputList.RoomDescription]}
            onChange={(e) => {
              formik.setFieldValue(InputList.RoomDescription, e?.target?.value ?? "");
              updateRoomField(InputList.RoomDescription, e?.target?.value);
            }}
            helperText={FormHelper.formikErrorMessage(formik, InputList.RoomDescription)}
            error={FormHelper.formikCheckError(formik, InputList.RoomDescription)}
            multiline
            rows={4}
            id={InputList.RoomDescription}
          />
          <TextareaDescription
            value={formik.values[InputList.RoomDescription]}
            isError={FormHelper.formikCheckError(formik, InputList.RoomDescription)}
          />
        </div>
        <div className={styles.formSection}>
          <div className={styles.formItemsWrapper}>
            <div className={styles.formItem}>
              <label
                htmlFor={InputList.FloorsNumber}
                className={cn(styles.inputLabel)}
              >
                Number of Floors
              </label>
              <TextField
                disabled={isEditRequestSubmitting}
                value={NumberHelper.formatFormInputValue(
                  formik.values[InputList.FloorsNumber],
                  true,
                )}
                onChange={(e) => {
                  FormLogic.setFormikCleanedNumberValue(
                    e?.target?.value,
                    formik,
                    InputList.FloorsNumber,
                  );
                  updateRoomField(
                    InputList.FloorsNumber,
                    +FormLogic.getFormikCleanedNumberValue(e?.target?.value),
                  );
                }}
                helperText={FormHelper.formikErrorMessage(formik, InputList.FloorsNumber)}
                error={FormHelper.formikCheckError(formik, InputList.FloorsNumber)}
                fullWidth
                className={styles.textField}
                id={InputList.FloorsNumber}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </div>
            <div className={styles.formItem}>
              <label
                htmlFor={InputList.SquareFootage}
                className={cn(styles.inputLabel)}
              >
                Square Footage
              </label>
              <TextField
                disabled={isEditRequestSubmitting}
                value={NumberHelper.formatFormInputValue(
                  formik.values[InputList.SquareFootage],
                  true,
                )}
                onChange={(e) => {
                  FormLogic.setFormikCleanedNumberValue(
                    e?.target?.value,
                    formik,
                    InputList.SquareFootage,
                  );
                  updateRoomField(
                    InputList.SquareFootage,
                    +FormLogic.getFormikCleanedNumberValue(e?.target?.value),
                  );
                }}
                helperText={FormHelper.formikErrorMessage(formik, InputList.SquareFootage)}
                error={FormHelper.formikCheckError(formik, InputList.SquareFootage)}
                fullWidth
                className={styles.textField}
                id={InputList.SquareFootage}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </div>
            <div className={styles.formItem}>
              <label
                htmlFor={InputList.Capacity}
                className={cn(styles.inputLabel, styles.required)}
              >
                Max capacity
              </label>
              <TextField
                disabled={isEditRequestSubmitting}
                value={NumberHelper.formatFormInputValue(formik.values[InputList.Capacity], true)}
                onChange={(e) => {
                  FormLogic.setFormikCleanedNumberValue(
                    e?.target?.value,
                    formik,
                    InputList.Capacity,
                  );
                  updateRoomField(
                    InputList.Capacity,
                    +FormLogic.getFormikCleanedNumberValue(e?.target?.value),
                  );
                }}
                error={FormHelper.formikCheckError(formik, InputList.Capacity)}
                helperText={FormHelper.formikErrorMessage(formik, InputList.Capacity)}
                fullWidth
                className={styles.textField}
                id={InputList.Capacity}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </div>
          </div>
        </div>
        <FormControlLabel
          control={
            <Switch
              checked={formik.values[InputList.ShowPricing]}
              onChange={(e) => {
                formik.setFieldValue(InputList.ShowPricing, e.target.checked);
                updateRoomField(InputList.ShowPricing, e.target.checked);
              }}
              className={styles.switchPricing}
            />
          }
          className={styles.showPricingControl}
          label={
            <Typography component="div">
              <span className={styles.showPricingControlName}>Show pricing</span>
              <span className={styles.showPricingControlText}>
                If pricing is turned Off the room will display “Inquire for Pricing” in the price
                section
              </span>
            </Typography>
          }
        />
        <div className={styles.formSection}>
          <div className={styles.formItemsWrapper}>
            <div className={cn(styles.formItem, styles.twoInRow)}>
              <label
                htmlFor={InputList.Price}
                className={cn(styles.inputLabel, {
                  [styles.required]: formik.values[InputList.ShowPricing],
                })}
              >
                Pricing value
              </label>
              <TextField
                disabled={isEditRequestSubmitting || !formik.values[InputList.ShowPricing]}
                value={`$${NumberHelper.formatFormInputValue(
                  formik.values[InputList.Price],
                  true,
                )}`}
                onChange={(e) => {
                  FormLogic.setFormikCleanedNumberValue(
                    e?.target?.value.slice(1),
                    formik,
                    InputList.Price,
                  );

                  updateRoomField(
                    InputList.Price,
                    +FormLogic.getFormikCleanedNumberValue(e?.target?.value.slice(1)) * 100,
                  );
                }}
                helperText={FormHelper.formikErrorMessage(formik, InputList.Price)}
                error={FormHelper.formikCheckError(formik, InputList.Price)}
                fullWidth
                className={styles.textField}
                required
                id={InputList.Price}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </div>
            <div className={cn(styles.formItem, styles.twoInRow)}>
              <label
                htmlFor={InputList.PriceDuration}
                className={cn(styles.inputLabel, {
                  [styles.required]: formik.values[InputList.ShowPricing],
                })}
              >
                Pricing duration
              </label>
              <Select
                labelId={InputList.PriceDuration}
                disabled={isEditRequestSubmitting || !formik.values[InputList.ShowPricing]}
                value={formik.values[InputList.PriceDuration]}
                fullWidth
                className={styles.textField}
                error={FormHelper.formikCheckError(formik, InputList.PriceDuration)}
                id={InputList.PriceDuration}
                onChange={(e) => {
                  formik.setFieldValue(InputList.PriceDuration, e.target.value);
                  updateRoomField(InputList.PriceDuration, e?.target?.value);
                }}
              >
                <MenuItem value={PriceDurationBE.PerDay}>{PriceDurationFE.PerDay}</MenuItem>
                <MenuItem value={PriceDurationBE.PerHalfDay}>{PriceDurationFE.PerHalfDay}</MenuItem>
                <MenuItem value={PriceDurationBE.PerHour}>{PriceDurationFE.PerHour}</MenuItem>
                <MenuItem value={PriceDurationBE.PerPerson}>{PriceDurationFE.PerPerson}</MenuItem>
              </Select>
            </div>
          </div>
        </div>
      </form>
      <div className={styles.formActionButtons}>
        <SaveButton
          isFullWidth
          isSaveButtonLoading={Boolean(isEditRequestSubmitting)}
          isSaveButtonDisabled={Boolean(isMobile ? isMobileSaveDisabled : isSaveButtonDisabled)}
          onSaveFormData={onSaveRoomFormData}
        />
        {selectedRoom?.isPublished ? (
          <BaseButton
            startIcon={
              <BaseIcon iconFileName={"trashWhiteIcon"} iconAlt="deactivate icon" iconSize={20} />
            }
            className={cn(styles.actionButton, {
              [styles.isDisabled]: isEditRequestSubmitting,
            })}
            onClick={() => deactivateRoom(selectedRoom?.id)}
            disabled={isEditRequestSubmitting}
          >
            Deactivate
          </BaseButton>
        ) : (
          <BaseButton
            startIcon={
              <BaseIcon iconFileName={"publishIcon"} iconAlt="publish icon" iconSize={20} />
            }
            className={cn(styles.actionButton, {
              [styles.isDisabled]: isPublishDisabled,
            })}
            disabled={isPublishDisabled}
            onClick={() => handlePublishRoomClick(selectedRoom?.id)}
          >
            Publish
          </BaseButton>
        )}
      </div>
      {isPhotosPopupOpen && (
        <RoomPhotosPopup
          photoFiles={roomPhotos}
          setPhotoFiles={setRoomPhotos}
          isOpen={isPhotosPopupOpen}
          handleClose={() => setIsPhotosPopupOpen(false)}
          handleSelectRoomPhotos={handleSelectRoomPhotos}
        />
      )}
    </div>
  );
}

export default forwardRef(RoomForm);
