import React from "react";
import { ImageFromCDN } from "blace-frontend-library";
import { BaseIcon, TwoOptionsPopup } from "@/src/component/base";

interface AddListingModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function AddListingModal({ isOpen, onClose }: AddListingModalProps) {
  const venueImageUrl = ImageFromCDN.imageSizeAndQuality(
    "addVenueImage.png",
    80,
    300,
    true
  );
  const vendorImageUrl = ImageFromCDN.imageSizeAndQuality(
    "addVendorImage.png",
    80,
    300,
    true
  );

  return (
    <TwoOptionsPopup
      isOpen={isOpen}
      onClose={onClose}
      popupTitle="Please select the type of listing"
      firstOption={{
        img: venueImageUrl,
        text: "Venue",
        link: "/listings/venue",
        icon: (
          <BaseIcon
            iconFileName="venueIconBlue"
            iconAlt="venue icon"
            iconSize={24}
          />
        ),
      }}
      secondOption={{
        img: vendorImageUrl,
        text: "Vendor",
        link: "/listings/vendor",
        isDisabled: true, // temporal, till we'll implement the Vendor
        icon: (
          <BaseIcon
            iconFileName="vendorIconBlue"
            iconAlt="vendor icon"
            iconSize={24}
          />
        ),
      }}
    />
  );
}

export default AddListingModal;
