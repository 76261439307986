import { ChangeEvent, useContext } from "react";
import { List, ListItem, Radio } from "@mui/material";
import cn from "classnames";
import { BaseIcon } from "@/src/component/base";
import {
  CategoryItem,
  ListingCategories,
} from "@/src/component/view/ListingManagement/ListingManagement";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import styles from "./CategoriesList.module.scss";

interface CategoriesListProps {
  categoriesList: CategoryItem[];
}

function CategoriesList({ categoriesList }: CategoriesListProps) {
  const listingManagementContext = useContext(ListingManagementContext);

  // todo: remove it after full list of contacts implementation
  const renderCategoryTitle = (category: ListingCategories): string => {
    if (category === ListingCategories.Contacts) {
      return "contact";
    }
    return category;
  };

  const categoryHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
      listingManagementContext?.categorySelectionHandler(event?.target?.value as ListingCategories);
  };

  return (
    <List className={styles.categoriesList} data-testid="categories-list">
      {categoriesList.map(({ id, category, completed }) => (
        <ListItem
          key={id}
          className={cn(styles.categoriesListItem, {
            [styles.isActive]: category === listingManagementContext?.categorySelected,
          })}
        >
          <label htmlFor={`category-${id}`} className={styles.categoryLabel}>
            {renderCategoryTitle(category)}
            <Radio
              id={`category-${id}`}
              checked={category === listingManagementContext?.categorySelected}
              onChange={categoryHandleChange}
              value={category}
              color="default"
              name="category"
              inputProps={{ "aria-label": category }}
              size="small"
              className={styles.categoryRadioButton}
            />
            {completed ? (
              <BaseIcon
                iconFileName="categorySelectedIcon"
                iconAlt="category selected"
                iconSize={18}
              />
            ) : (
              <div className={styles.fakeRadioButton}></div>
            )}
          </label>
        </ListItem>
      ))}
    </List>
  );
}

export default CategoriesList;
