import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { BaseButton, BaseLoader, SearchLogic } from "blace-frontend-library";
import { useNavigate } from "react-router-dom";
import { BaseIcon } from "@/src/component/base";
import { ListingTitleCell } from "@/src/component/view/Listings/component/ListingTitleCell";
import { ListingContactCell } from "@/src/component/view/Listings/component/ListingsTable/component/ListingContactCell";
import { ListingStatusLabel } from "@/src/component/view/Listings/component/ListingsTable/component/ListingStatusLabel";
import { ListingStatus, SearchDataTypes } from "@/src/type/blaceV2";
import { SearchItem } from "@/src/type/blaceV2/search/SearchType";
import styles from "./ListingTable.module.scss";

enum ListingsTableColumnNames {
  LISTING = "listing",
  CONTACT_EMAIL = "contactEmail",
  PRICE = "price",
  ROOMS_NUMBER = "roomsNumber",
  EDIT = "edit",
  STATUS = "status",
}

enum ListingsTableColumnHeaders {
  LISTING = "Listing",
  CONTACT_EMAIL = "Contact Email",
  PRICE = "Price",
  ROOMS_NUMBER = "Number of rooms",
  EDIT = "Edit",
  STATUS = "Status",
}

enum InquiryTableColumnsWidthPercent {
  Listing = 28,
  CONTACT_EMAIL = 24,
  PRICE = 16,
  ROOMS_NUMBER = 10,
  EDIT = 8,
  STATUS = 14,
}

function getColumnWidth(percent: number, fullWidth: number) {
  const lastCellSize = 110;
  const smallLastCellSize = 50;
  const paddingSize = 80;
  const MAX_TABLE_WIDTH = 1400;
  const screenWidth = fullWidth > MAX_TABLE_WIDTH ? MAX_TABLE_WIDTH : fullWidth;
  const tableWidth =
    screenWidth -
    (fullWidth > MAX_TABLE_WIDTH + paddingSize
      ? smallLastCellSize
      : lastCellSize);

  return (percent / 100) * tableWidth;
}

interface ListingTableProps {
  listings: SearchItem[];
  isLoading: boolean;
}

function ListingsTable({ listings, isLoading }: ListingTableProps) {
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(0);

  const onEditListingHandler = useCallback(
    (dataType: string, searchId: string) => {
      navigate(`/listings/${dataType}/${searchId}`);
    },
    [navigate]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: ListingsTableColumnNames.LISTING,
        headerName: ListingsTableColumnHeaders.LISTING,
        sortable: false,
        width: getColumnWidth(InquiryTableColumnsWidthPercent.Listing, screenWidth),
        renderCell: (params) => <ListingTitleCell listing={params.row} />,
      },
      {
        field: ListingsTableColumnNames.CONTACT_EMAIL,
        headerName: ListingsTableColumnHeaders.CONTACT_EMAIL,
        width: getColumnWidth(InquiryTableColumnsWidthPercent.CONTACT_EMAIL, screenWidth),
        sortable: false,
        renderCell: (params) => <ListingContactCell listing={params.row} />,
      },
      {
        field: ListingsTableColumnNames.PRICE,
        headerName: ListingsTableColumnHeaders.PRICE,
        width: getColumnWidth(InquiryTableColumnsWidthPercent.PRICE, screenWidth),
        sortable: false,
        renderCell: (params) => (
          <Typography className={styles.listingPrice}>
            {SearchLogic.formatSearchPricing(params.row)}
          </Typography>
        ),
      },
      {
        field: ListingsTableColumnNames.ROOMS_NUMBER,
        headerName: ListingsTableColumnHeaders.ROOMS_NUMBER,
        width: getColumnWidth(InquiryTableColumnsWidthPercent.ROOMS_NUMBER, screenWidth),
        sortable: false,
        renderCell: (params) => {
          const NO_DATA_TEXT = "---";
          return (
            <Typography className={styles.listingRooms}>
              {SearchLogic.getRoomsNumber(params.row) || NO_DATA_TEXT}
            </Typography>
          );
        },
      },
      {
        field: ListingsTableColumnNames.STATUS,
        headerName: ListingsTableColumnHeaders.STATUS,
        sortable: false,
        width: getColumnWidth(InquiryTableColumnsWidthPercent.STATUS, screenWidth),
        renderCell: (params) => {
          return <ListingStatusLabel listingStatus={params.row.isActive ? params.row.status : ListingStatus.UNPUBLISHED} />;
        },
      },
      {
        field: ListingsTableColumnNames.EDIT,
        headerName: ListingsTableColumnHeaders.EDIT,
        sortable: false,
        width: getColumnWidth(InquiryTableColumnsWidthPercent.EDIT, screenWidth),
        renderCell: (params) => {
          const { dataType, searchId } = params.row;
          return (
            <BaseButton
              className={styles.editButton}
              onClick={() => onEditListingHandler(dataType, searchId)}
              startIcon={
                <BaseIcon
                  iconFileName="editIcon"
                  iconAlt="edit icon"
                  iconSize={20}
                />
              }
              disabled={dataType === SearchDataTypes.Vendor} // temporal, till we'll implement the Vendor
            >
              Edit
            </BaseButton>
          );
        },
      },
    ],
    [onEditListingHandler, screenWidth]
  );

  useLayoutEffect(() => {
    function updateSize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div className={styles.listingsTableWrapper} data-testid="listingsTable">
      <DataGrid
        rows={listings}
        slots={{
          loadingOverlay: () => (
            <BaseLoader size={52} wrapperClassName={styles.loadingWrapper} />
          ),
        }}
        autoHeight
        columns={columns}
        className={styles.listingsTable}
        hideFooterPagination
        rowHeight={100}
        hideFooter
        loading={isLoading}
        disableColumnMenu
        disableColumnResize
        disableRowSelectionOnClick
        getRowId={({ searchId }) => searchId}
      />
    </div>
  );
}

export default ListingsTable;
