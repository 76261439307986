import React, { Fragment, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { ActivityLogic, KEYS, SharedConfigManager } from "blace-frontend-library";
import { BaseIcon } from "@/src/component/base";
import { BlaceV2Type } from "@/src/type";
import styles from "./Debug.module.scss";

// const storageHelperDebug = StorageHelper();

function Debug() {
  const [location, setLocation] = useState<
    BlaceV2Type.ActivityGeoLocation | undefined
  >(undefined);
  const [sessionId, setSessionId] = useState<String | undefined>(undefined);
  const [persistentId, setPersistentId] = useState<String | undefined>(
    undefined
  );
  const [windowDims, setWindowDims] = useState<{ w?: number; h?: number }>({});
  // const [debugLogging, setDebugLogging] =
  //   storageHelperDebug.useLocalStorage<boolean>(
  //     KEYS.DEBUG_DEV_MODE,
  //     Boolean(SharedConfigManager.getValue(KEYS.DEBUG_DEV_MODE))
  //   );

  const knownIconTypes: string[] = [
    "arrowDownIcon",
    "arrowLeft",
    "arrowLeftIcon",
    "arrowTopIcon",
    "bellIcon",
    "blacePartnersLogo",
    "bookIcon",
    "budgetIcon",
    "burgerIcon",
    "calendarIcon",
    "categorySelectedIcon",
    "checkFileButtonIcon",
    "checkRing",
    "clientNameSortingIcon",
    "closeIcon",
    "closeNotCircleGreyIcon",
    "closeInquiryIcon",
    "closeOutlineIcon",
    "completeIcon",
    "ContactsIcon",
    "ContactsIconCompleted",
    "copyIcon",
    "Date_today_light",
    "DetailsIcon",
    "DetailsIconCompleted",
    "Done_ring_round",
    "downloadIcon",
    "editIcon",
    "emailFillIcon",
    "emailIcon",
    "emailIconBig",
    "EmojiRatingFirst",
    "EmojiRatingSecond",
    "EmojiRatingThird",
    "EmojiRatingFifth",
    "EmojiRatingFourth",
    "errorRingIcon",
    "eventDateSortingIcon",
    "eventNameSortingIcon",
    "eventSearch",
    "eventsIcon",
    "excelIcon",
    "expandDown",
    "expandUp",
    "eyeIconBlue",
    "FeedbackIcon",
    "fileIcon",
    "fileIconYellow",
    "fileUploadIcon",
    "filterGrayIcon",
    "filtersIcon",
    "greenStarIcon",
    "Home",
    "HomeIcon",
    "iconGoogle",
    "iconSignUp",
    "imgLoadBoxIcon",
    "infoIcon",
    "InfoIcon",
    "infoBlueIcon",
    "lastUpdateSortingIcon",
    "layers",
    "logOutIcon",
    "moveDotsIcon",
    "MyEventsButton",
    "newStatusIcon",
    "noEventsIcon",
    "notCompletedCircleIcon",
    "notificationsIcon",
    "notificationsIconUnread",
    "outIcon",
    "pdfIcon",
    "personIcon",
    "phoneIcon",
    "PhotosIcon",
    "PhotosIconCompleted",
    "pinIcon",
    "pinMapToggleIcon",
    "placeIcon",
    "previewIcon",
    "PricingIcon",
    "PricingIconCompleted",
    "profileIcon",
    "profileListingCardIcon",
    "profileListingCardIconBlack",
    "profileListingCardIconGrey",
    "publishIcon",
    "questionIcon",
    "removeFileButtonIcon",
    "retryButtonIcon",
    "retryBlueIcon",
    "retryLightBlueIcon",
    "plusIcon",
    "RoomsIcon",
    "RoomsIconCompleted",
    "saveDraftIcon",
    "searchError",
    "searchResult",
    "signInIcon",
    // "skeletonDesktop",
    // "skeletonMobile",
    "sortIcon",
    "stillInContactIcon",
    "strokeCalendarIcon",
    "submissionDateSortingIcon",
    "SubtractIcon",
    "TagsIcon",
    "TagsIconCompleted",
    "trashIcon",
    "trashIconBlue",
    "trashLightBlueIcon",
    "trashRedIcon",
    "trashWhiteIcon",
    "uploadCloudIcon",
    "uploadImgIcon",
    "vendorIconBlue",
    "vendorListingCardIcon",
    "vendorListingCardIconBlack",
    "vendorListingCardIconGrey",
    "vendorToggleIcon",
    "venueIconBlue",
    "venueListingCardIcon",
    "venueListingCardIconBlack",
    "venueListingCardIconGrey",
    "venueToggleIcon",
    "videoListingCardIcon",
    "videoListingCardIconBlack",
    "videoListingCardIconGrey",
    "walletListingCardIcon",
    "walletListingCardIconBlack",
    "walletListingCardIconGrey",
    "wordIcon",
    "yellowStarIcon",
  ];

  useEffect(() => {
    (async () => {
      try {
        setLocation(await ActivityLogic.getGeoLocation());
        setSessionId(await ActivityLogic.SESSION_ID());
        setPersistentId(await ActivityLogic.PERSISTENT_ID());
        setWindowDims({ w: window.innerWidth, h: window.innerHeight });
      } catch (error) {
        /**this doesn't matter */
      }
    })();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDims({ w: window.innerWidth, h: window.innerHeight });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={styles.debug}>
      <Typography variant="h1">DEBUG</Typography>
      <div className={styles.debugOptions}>
        {/*<div className={styles.debugOptionRow}>*/}
        {/*  <Typography variant="h4">Console Logging:</Typography>*/}
        {/*  <BaseButton*/}
        {/*    variant={`${debugLogging}` === "true" ? "contained" : "outlined"}*/}
        {/*    size="small"*/}
        {/*    className={""}*/}
        {/*    onClick={() => {*/}
        {/*      setDebugLogging(!debugLogging);*/}
        {/*    }}*/}
        {/*    suppressHydrationWarning={true}*/}
        {/*  >*/}
        {/*    {`${debugLogging}` === "true" ? "Turn Off" : "Turn On"}*/}
        {/*  </BaseButton>*/}
        {/*</div>*/}
        {location && (
          <>
            <div className={styles.debugOptionRow}>
              <Typography variant="h4">Estimated Latitude:</Typography>
              <Typography variant="h5">{location.latitude}</Typography>
            </div>
            <div className={styles.debugOptionRow}>
              <Typography variant="h4">Estimated Longitude:</Typography>
              <Typography variant="h5">{location.longitude}</Typography>
            </div>
            <div className={styles.debugOptionRow}>
              <Typography variant="h4">Estimated City:</Typography>
              <Typography variant="h5">{location.city}</Typography>
            </div>
            <div className={styles.debugOptionRow}>
              <Typography variant="h4">Estimated State/Region:</Typography>
              <Typography variant="h5">{location.state}</Typography>
            </div>
            <div className={styles.debugOptionRow}>
              <Typography variant="h4">Estimated Country:</Typography>
              <Typography variant="h5">{location.country}</Typography>
            </div>
          </>
        )}
        {windowDims?.w && (
          <>
            <div className={styles.debugOptionRow}>
              <Typography variant="h4">Window Width:</Typography>
              <Typography variant="h5">{windowDims.w}px</Typography>
            </div>
            <div className={styles.debugOptionRow}>
              <Typography variant="h4">Window Height:</Typography>
              <Typography variant="h5">{windowDims.h}px</Typography>
            </div>
          </>
        )}
        <div className={styles.debugOptionRow}>
          <Typography variant="h4">Session ID:</Typography>
          <Typography variant="h5">{sessionId}</Typography>
        </div>
        <div className={styles.debugOptionRow}>
          <Typography variant="h4">Persistent ID:</Typography>
          <Typography variant="h5">{persistentId}</Typography>
        </div>
        <div className={styles.debugOptionRow}>
          <Typography variant="h4">All Env ID:</Typography>
          <Typography variant="h5">{SharedConfigManager.getValue(KEYS.APP_TENANT)}</Typography>
        </div>
        <div className={styles.debugOptionRow}>
          <Typography variant="h4">Environment:</Typography>
          <Typography variant="h5">{SharedConfigManager.getValue(KEYS.ENV)}</Typography>
        </div>
        <div className={styles.debugOptionRowFlexible}>
          <Typography variant="h4">Known Icons:</Typography>
          <Typography variant="h5">
            {knownIconTypes.map((iconType) => (
              <Fragment key={iconType}>
                <>{iconType}: </>
                <BaseIcon
                  iconFileName={iconType}
                  iconAlt={iconType}
                  iconSize={20}
                />
                -
                <BaseIcon
                  iconFileName={iconType}                  
                  iconAlt={iconType}
                  iconSize={20}
                  className={styles.iconBackground}
                />
                <br />
              </Fragment>
            ))}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Debug;
