import { ImageFromCDN, ImageLogic, Log } from "blace-frontend-library";
import { SERVER_ERROR_MESSAGE } from "@/src/const";
import { AzureBlobService, B2BSearchServiceV2 } from "@/src/service";
import { RoomPhotoFile, SortablePhotoFile } from "@/src/type/app";

enum PhotoValidFormat {
  PNG = "image/png",
  JPG = "image/jpeg",
  GIF = "image/gif",
}

export const validateImageFiles = (photos: FileList | File[]) => {
  const BASE_ERROR = "Unsupported file format or file size exceeds 5 Mb for the following files: ";
  let error = null;
  const validFormats = Object.values(PhotoValidFormat) as string[];
  const maxSizeInBytes = 5 * 1024 * 1024; // 5MB in bytes
  const invalidFiles: File[] = [];
  const validFiles = Array.from(photos).filter((file) => {
    if (validFormats.includes(file.type) && file.size <= maxSizeInBytes) {
      return true;
    }
    invalidFiles.push(file);
    return false;
  });
  if (invalidFiles.length) {
    error = BASE_ERROR + invalidFiles.map((file) => file.name).join(", ");
  }
  return { validFiles, error };
};

export const uploadSearchImage = async (file: File, searchId?: string) => {
  try {
    const linkFileUploadResponse =
      searchId &&
      (await B2BSearchServiceV2.getSearchItemImageUploadLink(searchId, file.type, file.size));

    if (!linkFileUploadResponse?.success) {
      throw new Error(
        `Failed to receive an upload link for image (${file.name} - ${file.size}) of Search item (${searchId}).`,
      );
    }

    const { fileNameCanonical, link, blobName } = linkFileUploadResponse?.body?.payload;

    const putFileToServerResponse = await AzureBlobService.putFileToServer(
      link,
      fileNameCanonical,
      file,
      file.type,
    );

    if (!putFileToServerResponse?.ok) {
      throw new Error(
        `Failed to upload image (${file.name} - ${file.size}) of Search item (${searchId}).`,
      );
    }

    return { blobName, link };
  } catch (e) {
    Log.logToDataDog(Log.LogLevel.ERROR, "PhotosContent.tsx", "upload image link Error", [e]);
    return null;
  }
};

export const uploadPhoto = async (
  photo: RoomPhotoFile | SortablePhotoFile,
  searchId: string,
  setError: (err: string) => void,
) => {
  if (!photo.loading || !photo.file) {
    return photo;
  }
  const fileUploadRes = await uploadSearchImage(photo.file, searchId);

  if (!fileUploadRes?.blobName) {
    setError(SERVER_ERROR_MESSAGE);
  }
  return {
    ...photo,
    imageHash: fileUploadRes?.blobName,
    originLink: fileUploadRes?.link,
    loading: false,
    failed: !fileUploadRes?.blobName,
    isSelected: true,
  };
};

export const uploadPhotoLinks = async (
  photoFiles: RoomPhotoFile[] | SortablePhotoFile[],
  searchId: string,
  setError: (err: string) => void,
) => {
  const updatedPhotos = await Promise.all(
    photoFiles.map((photo) => uploadPhoto(photo, searchId, setError)),
  );

  const filteredPhotos = updatedPhotos.filter((photo) => !photo.failed);
  return filteredPhotos;
};

export const getFileImageUrl = (file: SortablePhotoFile | RoomPhotoFile) => {
  // for files that are not yet uploaded to BE imageHash has URL from URL.createObjectURL()
  if (file.loading) {
    return file.imageHash;
  }
  const imageExtension = ImageLogic.ensureImageExtension(file.imageHash);
  return ImageFromCDN.imageSizeAndQuality(imageExtension, 80, 300, true);
};
